import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Modal } from 'react-bootstrap';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss'

import { ButtonInput } from '../forms/buttons';

/*
    //WARNING: Some of the following props may have been deprecated when adding the image cropping

    props = {
        title: string,
        text: string,
        show: object, //opens modal
        hide: function, //closes modal
        multiple: object, //allow multiple files
        maxFileNr: number,
        saveImage: function, //receives a file
        maxSizeMB: number,
        maxWidth: number,
        maxHeight: number,
        tolerancePercentage: number, //ex: 10 (meaning 10%). This is to avoid to strict demands to the user
        aspectRatios: [{
            name: string //something like 1:1, 16:9, etc,
            ratio: number //divide 16/9, etc
        }]
    }
*/
export function ImageUploadModal(props) {
    //Image restrictions
    //const maxSizeMB = props.maxSizeMB ? props.maxSizeMB : 2; //2MB
    //const maxSize = maxSizeMB * 1024 * 1024;

    const maxWidth = props.maxWidth ? props.maxWidth : null;
    const maxHeight = props.maxHeight ? props.maxHeight : null;

    const aspectRatios = props.aspectRatios && Array.isArray(props.aspectRatios) && props.aspectRatios.length > 0
        ? props.aspectRatios
        : [];

    //Image crop
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const cropSettings = {
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    }

    if (aspectRatios[0] && aspectRatios[0].ratio) {
        //We're only using the first given aspect ratio since we added image cropping
        cropSettings.aspect = aspectRatios[0].ratio
    }

    const [crop, setCrop] = useState(cropSettings);

    const [upImg, setUpImg] = useState(null);
    const [upVideo, setUpVideo] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    //Messages
    const [message, setMessage] = useState(null);
    //const [uploadSuccess, setUploadSuccess] = useState(null);

    const onLoad = (e) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                    height: 100,
                },
                aspectRatios[0] ? aspectRatios[0].ratio : 1,
                width,
                height
            ),
            width,
            height
        )
        setCrop(crop)

        imgRef.current = e.currentTarget;

        setMessage("Please crop your image before saving. Click and drag over the image to crop it.");
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        /*
            Force image size restrictions. Instead of resizing the original image and then cropping, we'll resize the
            cropped image in order to optimise the image quality.
        */
        var resizeRatio = 1;

        if (maxWidth || maxHeight) {
            if (maxWidth && crop.width * scaleX * resizeRatio > maxWidth) {
                resizeRatio = maxWidth / (crop.width * scaleX);
            }

            if (maxHeight && crop.height * scaleY * resizeRatio > maxHeight) {
                resizeRatio = maxHeight / (crop.height * scaleY);
            }
        }

        //const pixelRatio = window.devicePixelRatio;

        //canvas.width = crop.width * pixelRatio;
        //canvas.height = crop.height * pixelRatio;
        canvas.width = crop.width * scaleX * resizeRatio;
        canvas.height = crop.height * scaleY * resizeRatio;

        const ctx = canvas.getContext('2d');

        //ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX * resizeRatio,
            crop.height * scaleY * resizeRatio
        );
    }, [completedCrop]);

    function reset() {
        setUpImg(null);
        setUpVideo(null);
        setCompletedCrop(null);
        setCrop(cropSettings);
    }

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            reset();

            if (props.video) {
                const maxSize = 10 * 1024 * 1024;

                if (e.target.files[0].size > maxSize) {
                    setMessage("Error: The maximum file size is " + (10) + "MB!");
                    return;
                } else {
                    setUpVideo(e.target.files[0]);
                }

            } else {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const imageUrl = reader.result?.toString() || '';
                    setUpImg(imageUrl)
                });
                reader.readAsDataURL(e.target.files[0]);
            }

        }
    }

    function generateImage(canvas, crop) {
        if (!canvas || !crop) {
            return;
        }

        if (props.saveFormat) {
            canvas.toBlob((blob) => checkImageRestrictions(blob), props.saveFormat, 1);
        } else {
            canvas.toBlob((blob) => checkImageRestrictions(blob), 'image/jpeg', 1);
        }
    }

    function checkImageRestrictions(file) {
        /*
        if(file.size > maxSize) {
            setMessage("Error: The maximum file size is " + maxSizeMB + "MB!");
            return;
        }
        */

        props.saveImage(file);

        setMessage("Success");
        reset();
    }

    function checkVideoRestrictions(file) {
        props.saveImage(file);

        setMessage("Success"); 
        reset();
    }

    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>
                        {props.title ? props.title : 'Upload Image'}
                    </div>
                    {/*}
                    <div style={{fontSize: '0.9rem', fontWeight: "normal", color: "#656565"}}>
                        Files allowed: png/jpg
                    </div>
                    {*/}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="input-group mb-3">
                    {/*}
                    <div className="input-group-prepend">
                        <span className="input-group-text">Upload</span>
                    </div>
                    {*/}
                    <div className="custom-file">
                        <input
                            id="inputGroupFile01"
                            type="file"
                            className="custom-file-input"
                            //accept="image/png, image/jpg, image/jpeg"
                            accept={props.video ? "video/mp4" : "image/*"}
                            //multiple={props.multiple}
                            multiple={false} //Only allow one image at a time for now
                            //onChange={handleImage}
                            onChange={onSelectFile}
                        />
                        <label className="custom-file-label">
                            {props.text ? props.text : 'Choose file'}
                        </label>
                    </div>
                </div>

                {!upImg && message && (
                    <div className="mx-1">
                        {message}
                    </div>
                )}

                {(!props.video && upImg) ? (
                    <>
                        <ReactCrop
                            src={upImg}
                            crop={crop}
                            keepSelection
                            aspect={aspectRatios[0] ? aspectRatios[0].ratio : undefined}
                            maxWidth={maxWidth ? maxWidth : null}
                            maxHeight={maxHeight ? maxHeight : null}
                            onChange={(crop) => setCrop(crop)}
                            onComplete={(crop) => setCompletedCrop(crop)}
                        >
                            <img
                                src={upImg}
                                alt='Upload'
                                onLoad={onLoad}
                            />
                        </ReactCrop>

                        {/* The crop preview is required for this to work, but we don't want to show it */}
                        <div style={{ display: 'none' }}>
                            <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                    width: Math.round(completedCrop?.width ?? 0),
                                    height: Math.round(completedCrop?.height ?? 0)
                                }}
                            />
                        </div>

                        {message && (
                            <div className="mx-1">
                                {message}
                            </div>
                        )}

                        <div>
                            <ButtonInput
                                trigger
                                label="Upload"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                set={() => generateImage(previewCanvasRef.current, completedCrop)}
                                style={
                                    !completedCrop?.width || !completedCrop?.height
                                        ? { backgroundColor: "#ededed" }
                                        : { backgroundColor: "#fbd731" }
                                }
                            />
                        </div>
                    </>
                )
                    :
                    <>
                        {upVideo &&
                            <>
                                {message && (
                                    <div className="mx-1">
                                        {message}
                                    </div>
                                )}

                                <div>
                                    <ButtonInput
                                        trigger
                                        label="Upload"
                                        set={() => checkVideoRestrictions(upVideo)}
                                        style={
                                            { backgroundColor: "#fbd731" }
                                        }
                                    />
                                </div>
                            </>
                        }
                    </>
                }
            </Modal.Body>
        </Modal>
    );
}
