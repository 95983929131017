import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faDiscord } from '@fortawesome/free-brands-svg-icons';

import QUDOServer from '../../../helpers/QUDOServerConnection';
import { SimpleButton } from '../forms/buttons';

const closeButton = require("../../../images/definitive/btn-close.svg");

export default function AirdropRankingsModal(props) {
    const info = useSelector(state => state.info);

    const [loading, setLoading] = useState(false);
    const [accountData, setAccountData] = useState(null);

    useEffect(() => {
        setLoading(true);

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/airdrop`, {
			username: info.username
		}, {
			withCredentials: true
		})
            .then((response) => {
                const data = response.data;

				if (data.account_activity_reward && data.account_age_reward) {
                    setAccountData({
                        tier: data.tier,
                        firstTokenGrabEpoch: data.firstTokenGrabEpoch,
                        reward: data.account_activity_reward + data.account_age_reward
                    })
				}

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                props.hide();
            });
    }, []);

    if (
        info && (
            info.type === process.env.REACT_APP_USER_TYPE
            || info.type === process.env.REACT_APP_GAMEDEV_TYPE
            || info.type === process.env.REACT_APP_PROVIDER_TYPE
        )
    ) {
        return (
            <Modal
                show={props.show}
                onHide={props.hide}
                size='lg'
                centered
            >
                <Modal.Body id='no-padding'>
                    {loading ? (
                        <div className='migration-main-loading my-5'>
                            <div className='migration-fof-loading'>
                                <h1 className='m-0 ml-3'>
                                    <ClipLoader color='#000000' />
                                </h1>
                            </div>
                        </div>
                    ) : (
                        <div className='airdrop-popup'>
                            <div className='airdrop-header'>
                                <div className='airdrop-close' onClick={props.hide}>
                                    <img src={closeButton} alt={"Close Modal"} />
                                </div>
                                <h3>
                                    Current Airdrop Allocation
                                </h3>
                            </div>

                            <div className='airdrop-rewards'>
                                {accountData &&
                                    <>
                                        <div className='airdrop-total'>
                                            {accountData.reward === 0 ?
                                                <>N/A </>
                                                :
                                                <>
                                                    {(accountData.reward).toLocaleString('de-DE', { minimumFractionDigits: 4 })}
                                                </>
                                            }
                                            <span>QUDO</span>
                                        </div>
                                        <div className='airdrop-info'>
                                            {accountData.tier ?
                                                <>
                                                    Tier {accountData.tier} | #{accountData.firstTokenGrabEpoch}
                                                </>
                                                :
                                                <>Grab tokens at least once to qualify for the airdrop</>
                                            }
                                        </div>
                                    </>}
                            </div>
                            <div
                                className='airdrop-leaderboard text-center'
                                onClick={() => window.open(`${window.location.origin}/airdrop/rankings/users?account=${info.account_name}`, '_blank')}
                            >
                                <div>
                                    Check Airdrop Leaderboard
                                    <span>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </span>
                                </div>
                            </div>
                            <div className='airdrop-socials text-center'>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://t.me/qudocoin'
                                    style={{ color: '#000000' }}
                                >
                                    <FontAwesomeIcon icon={faTelegram} />
                                </a>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://discord.com/invite/UK9MHRZ'
                                    style={{ color: '#000000' }}
                                >
                                    <FontAwesomeIcon icon={faDiscord} />
                                </a>
                            </div>
                            <div className=''>
                                <SimpleButton
                                    label={'Know more'}
                                    style={{ fontSize: '0.75rem', height: '3rem' }}
                                    callback={() => window.open('https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595', '_blank')}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        );
    } else {
        return null;
    }
}
