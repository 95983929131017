import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import * as actions from "../actions";

import Layout from '../layouts/layout';
//import withClearCache from "./ClearCache";
import Community from "./Community";
import Wallet from "./Wallet";
import EditProfile from "./EditProfile";
import UserActivity from "./UserActivity";
import GamedevActivity from "./GamedevActivity";
import GamedevMarketplace from './GamedevMarketplace';
import NewGame from "./gamedev/gameManagement/newGameComponent/newGame";
import NewApplication from "./gamedev/applicationManagement/newApplicationComponent/newApplication";
import GameEditScreen from "./gamedev/gameManagement/gameEditScreen";
import ApplicationEditScreen from "./gamedev/applicationManagement/applicationEditScreen";
import ProviderActivity from "./ProviderActivity";
import ProviderMarketplace from "./ProviderMarketplace";
import ProviderEarnings from './ProviderEarnings';
import GuildActivity from './GuildActivity';

const qudoAlphaLogo = require("../images/QUDO_LogoAlpha.svg");

//const ClearCacheComponent = withClearCache(MainApp);

export const Loading = () => {
	return (
		<div
			className="text-center"
			style={{
				position: "fixed",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)"
			}}
		>
			Loading...
			<br />
			<img src={qudoAlphaLogo} alt="qudo big logo" style={{ height: "8rem", marginTop: "1rem" }} />
		</div>
	);
}

/*
export default function App({history, ual}) {
	return (
		<ClearCacheComponent ual={ual} history={history}/>
	);
}
*/

export default function App({ history, ual }) {
	const dispatch = useDispatch();

	const info = useSelector(state => state.info);

	const [redirectAddress, setRedirectAddress] = useState('/');
	const [communityModal, setCommunityModal] = useState(null);

	useEffect(() => {
		const url = new URL(window.location.href);

		if (url.search) {
			setRedirectAddress(redirectAddress + url.search);
		}

		dispatch(actions.fetchUser());
		dispatch(actions.getGames());
		dispatch(actions.getConfig());

		//Cirqle Event Tracker PageView event
		setTimeout(() => {
			if (window && window.cqet) {
				try {
					window.cqet('send', 'PageView');
				} catch (error) {
					console.log(error);
				}
			}
		}, 1000);
	}, []);

	useEffect(() => {
		if (ual) {
			dispatch(actions.setUal(ual));
		} else {
			dispatch(actions.setUal(null));
		}
	}, [ual]);

	return (
		<ConnectedRouter history={history}>
			{info !== "" ? (
				<Switch>
					<Layout modal={communityModal} setCommunityModal={setCommunityModal}>
						<Route exact path={process.env.REACT_APP_HOME} component={() =>
							<Community setCommunityModal={setCommunityModal} />
						} />

						{
							info && (
								info.type === process.env.REACT_APP_USER_TYPE
								|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
								|| info.type === process.env.REACT_APP_PROVIDER_TYPE
								|| info.type === process.env.REACT_APP_GUILD_TYPE
							) ? (
								<>
									<Route exact path={process.env.REACT_APP_USER_WALLET} component={() =>
										<Wallet />
									} />
									<Route exact path={process.env.REACT_APP_USER_EDIT_PROFILE} component={() =>
										<EditProfile
											info={info}
											fetchNewInfo={() => dispatch(actions.fetchUser())}
										/>
									} />
								</>
							) : (
								<Redirect to={redirectAddress} />
							)
						}

						{

							info && (
								info.type === process.env.REACT_APP_USER_TYPE
								|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
								|| info.type === process.env.REACT_APP_PROVIDER_TYPE
							) && (
								<>
									<Route exact path={process.env.REACT_APP_USER_GAME_ACTIVITY} component={() =>
										<UserActivity
											info={info}
											fetchNewInfo={() => dispatch(actions.fetchUser())}
										/>
									} />
								</>
							)
						}

						{(info && info.type === process.env.REACT_APP_GAMEDEV_TYPE) && (
							<>
								<Route exact path={process.env.REACT_APP_GAMEDEV_GAMES} component={() =>
									<GamedevActivity
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_MARKETPLACE} component={() =>
									<GamedevMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_ADD_GAME} component={() =>
									<NewGame
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_ADD_APP} component={() =>
									<NewApplication
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={`${process.env.REACT_APP_GAMEDEV_EDIT_GAME}/:game`} component={(props) =>
									<GameEditScreen
										game={props.match.params.game}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={`${process.env.REACT_APP_GAMEDEV_EDIT_APP}/:app`} component={(props) =>
									<ApplicationEditScreen
										application={props.match.params.app}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
							</>
						)}

						{info && info.type === process.env.REACT_APP_PROVIDER_TYPE && (
							<>
								<Route exact path={process.env.REACT_APP_PROVIDER_SERVICES} component={() =>
									<ProviderActivity
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_MARKETPLACE} component={() =>
									<GamedevMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_PROVIDER_MANAGEMENT} component={() =>
									<ProviderMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_PROVIDER_EARNINGS} component={() =>
									<ProviderEarnings
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
							</>
						)}

						{info && info.type === process.env.REACT_APP_GUILD_TYPE && (
							<>
								<Route exact path={process.env.REACT_APP_GUILD_SETTINGS} component={() =>
									<GuildActivity />
								} />
							</>
						)}
					</Layout>
				</Switch>
			) : (
				<Loading />
			)}
		</ConnectedRouter>
	);
}
