import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import Moment from 'moment-timezone';
import ReactTooltip from "react-tooltip";
import {ClipLoader} from 'react-spinners';

import * as actions from "../../actions";

import QUDOServer from "../../helpers/QUDOServerConnection";

import {MessageModal} from "../components/modals/modals.js";

//import whiteArrow from "../../images/definitive/icn-transfers-qudo-white.png";
import yellowArrow from "../../images/definitive/icn-transfers-qudo-yellow.png";

export function ClaimButton(props) {
    const dispatch = useDispatch();

	const balance = useSelector(state => state.balance);
    const currentUser = useSelector(state => state.info);
	const pendingReward = useSelector(state => state.pendingReward);

	const [modalMessage, setModalMessage] = useState('');
    const [claimLoading, setClaimLoading] = useState(false);
    const [pendingRewardValue, setPendingRewardValue] = useState(0);

    const networkRewardsLocked = currentUser && currentUser.qudoManaged && balance && !isNaN(balance.usePercentage) && Number(balance.usePercentage) >= 100;

    useEffect(() => {
        dispatch(actions.getPendingReward());
    }, []);

    useEffect(() => {
        if(pendingReward) {
            try {
                setPendingRewardValue(Number(String(pendingReward).split(' ')[0]));
            } catch(error) {
                //console.log(error);
            }
        } else {
            setPendingRewardValue(0);
        }
    }, [pendingReward]);

    function claim() {
        setClaimLoading(true);

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/eos/grab`, {
            withCredentials: true
        })
        .then(data => {
            dispatch(actions.updateBalance());
            setModalMessage(`Your Rewards: ${pendingReward}`);
            dispatch(actions.getPendingReward());
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setClaimLoading(false);
        });
    }

    return(
        <>
            {claimLoading ? (
                <div className="text-center mt-4">
                    <ClipLoader color="#282725"/>
                </div>
            ) : (
                <button
                    id="claimpendingbtn"
                    className="shadow"
                    style={{
                        margin: "0px",
                        cursor: pendingRewardValue > 0 ? "" : "initial",
                        backgroundColor: pendingRewardValue > 0 ? (networkRewardsLocked ? "#f54337" : "") : "#b2b2b2"
                    }}
                    onClick={() => {
                        if(pendingRewardValue > 0) {
                            claim();
                        }
                    }}
                >
                    <table className="table m-0">
                        <tbody>
                            <tr>
                                <td style={{width: "75%", verticalAlign: 'middle', borderTop: '0px'}}>
                                    <div>
                                        PENDING REWARDS
                                    </div>
                                    <div>
                                        {pendingRewardValue > 0 ? pendingReward : "You currently don't have any pending rewards"}
                                    </div>
                                </td>
                                {pendingRewardValue > 0 && (
                                    <>
                                    <td
                                        className="text-center py-0"
                                        style={{
                                            width: "24%",
                                            verticalAlign: 'middle',
                                            borderTop: '0px',
                                            borderLeft: `2px solid ${networkRewardsLocked ? '#d23a2f' : '#edcc00'}`
                                        }}
                                    >
                                        <div id="claimtext">
                                            GRAB REWARDS
                                        </div>
                                    </td>
                                    </>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </button>
            )}

            {networkRewardsLocked && (
                <div className="mx-2" style={{color: '#d23a2f'}}>
                    NETWORK REWARDS are temporally DISABLED.
                    You need to take control of your TELOS account to re-enable NETWORK REWARDS.
                </div>
            )}

            <MessageModal
                show={!!modalMessage} 
                message={modalMessage}
                hide={() => setModalMessage('')}
            />
        </>
    );
}

export default function TransactionHistory() {
    //const dispatch = useDispatch();

	//const balance = useSelector(state => state.balance);
    const currentUser = useSelector(state => state.info);
	//const pendingReward = useSelector(state => state.pendingReward);

    const [transactions, setTransactions] = useState([]);
	//const [modalMessage, setModalMessage] = useState('');
    //const [claimLoading, setClaimLoading] = useState(false);

    //const networkRewardsLocked = currentUser && currentUser.qudoManaged && balance && !isNaN(balance.usePercentage) && Number(balance.usePercentage) >= 100;

    useEffect(() => {
        getHistory();
    }, []);

    function getHistory() {
        const maxNumberOfTransactionsToShow = 20;

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/eos/history/transactions`, {
            withCredentials: true
        })
        .then(data => {
            // dispatch(actions.getPendingReward());

            // if(currentUser.type === process.env.REACT_APP_USER_TYPE) {
            //     dispatch(actions.getPendingReward());
            // }

            if(data.data && Array.isArray(data.data)) {
                const trxs = data.data.slice(0, maxNumberOfTransactionsToShow);

                var newTransactions = [];

                for(var j = 0; j < trxs.length; j++) {
                    const i = trxs[j];

                    //const date = Moment(i.block_time).tz(Moment.tz.guess()).fromNow();
                    //const absoluteTime = Moment(i.block_time).tz(Moment.tz.guess()).format();

                    newTransactions.push({
                        //time: date,
                        //absoluteTime: absoluteTime,
                        block_time: i.block_time,
                        sender: i.from,
                        receiver: i.to,
                        memo: i.memo,
                        link: i.link,
                        id: i.id,
                        amount: i.amount,
                        type: i.type
                    });
                }

                setTransactions(newTransactions);
            }
        });
    }

    /*
    function claim() {
        setClaimLoading(true);

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/eos/claim`, {
            withCredentials: true
        })
        .then(data => {
            getHistory();
            dispatch(actions.updateBalance());
            setModalMessage(`Your Rewards: ${pendingReward}`);
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setClaimLoading(false);
        });
    }
    */

    function renderCard(trx) {
        return(
            <>
            <div
                data-tip
                data-for='transaction_details'
                key={trx.link}
                className="row transactioncard mb-1 cursor-pointer"
                style={{height: 'initial'}}
                onClick={() => window.open(trx.link, '_blank')}
            >
                <div className="col-4">
                    <div style={{textAlign: 'right'}}>
                        {trx.sender}
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <img alt="" src={yellowArrow} style={{maxWidth: '1.5rem'}}/>
                    </div>
                    <div className='mt-2' style={{textAlign: 'left', fontSize: '0.9rem'}}>
                        {Moment(trx.block_time).tz(Moment.tz.guess()).format('DD/MM/YYYY')}
                    </div>
                </div>

                <div className="col-4" style={{textAlign: 'center'}}>
                    <div
                        className="mt-4"
                        style={{
                            fontSize: '1.25rem',
                            color: trx.sender === currentUser.account_name ? 'red' : 'green'
                        }}
                    >
                        {Number(String(trx.amount).split(' ')[0])} QUDO
                    </div>
                </div>

                <div className="col-4">
                    <div style={{textAlign: 'left'}}>
                        {trx.receiver}
                    </div>
                    <div style={{textAlign: 'left'}}>
                        <img alt="" src={yellowArrow} style={{maxWidth: '1.5rem', transform: 'rotate(-90deg)'}}/>
                    </div>
                    <div className='mt-2' style={{textAlign: 'right', fontSize: '0.9rem'}}>
                        {Moment(trx.block_time).tz(Moment.tz.guess()).format('hh:mm A')}
                    </div>
                </div>

                <div className="col-12 mt-2">
                    <div style={{textAlign: 'center'}}>
                        {trx.memo}
                    </div>
                </div>
            </div>

            <ReactTooltip id='transaction_details' type='light'>
                <span>Full information about this transaction</span>
            </ReactTooltip>
            </>
        );
    }

    return(
        currentUser && (
        <>
            <div className="w-100">
                <div className="mb-4">
                    <ClaimButton/>
                </div>

                <div className="text-center">
                    <p className="ml-1" style={{float: "left"}}>
                        <a
                            data-tip
                            data-for='latest_transactions'
                            target='_blank'
                            rel='noopener noreferrer'
                            //href={'https://telos-test.bloks.io/account/' + currentUser.account_name}
                            href={`https://eosauthority.com/account/${currentUser.account_name}?network=telostest`}
                            style={{color: "black"}}
                        >
                            Latest actions
                        </a>
                    </p>
                </div>

                <div style={{clear: "both"}} className="scroll-75">
                    {Array.isArray(transactions) && transactions.length > 0 ? (
                        transactions.map(trx => {
                            return renderCard(trx);
                        })
                    ) : (
                        <div className="ml-1">
                            You don't have any recent transactions
                        </div>
                    )}
                </div>
            </div>

            <ReactTooltip id='latest_transactions' type='light'>
                <span>Your TELOS account info and transactions</span>
            </ReactTooltip>

        </>
        )
    );
}
