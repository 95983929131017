import React, { useState, useEffect } from 'react';
import axios from 'axios';

//import Swiper from 'react-id-swiper';
//import 'swiper/css/swiper.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";

import SwipeableViews from 'react-swipeable-views';
import Pagination from '../components/react-swipeable-views-pagination/Pagination';

import { DropdownInput } from '../components/forms/dropdowns';

import usePrevious from '../../helpers/usePrevious';
import useDebounce from '../../helpers/useDebounce';
import useWindowSize from '../../helpers/useWindowSize';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const fallBack = require('../../images/fallbackAvatar.png');

const unavailableBanner = require('../../images/banners/QUDO_TemporarilyUnavailable.png');
const comingSoonBanner = require('../../images/banners/QUDO_ComingSoon.png');
const alphaBanner = require('../../images/banners/QUDO_Alpha.png');
const betaBanner = require('../../images/banners/QUDO_Beta.png');
const newBanner = require('../../images/banners/QUDO_New.png');

export default function GamesList({ games, featuredGame, showDetails }) {
    //const windowSize = useWindowSize();
    const windowSize = useDebounce(useWindowSize(), 250);
    const previousWindowSize = usePrevious(windowSize);

    const [platform, setPlatform] = useState('');
    const previousPlatform = usePrevious(platform);

    const [toRenderDesktop, setToRenderDesktop] = useState([]);
    const [toRenderMobile, setToRenderMobile] = useState([]);

    const [shouldRender, setShouldRender] = useState(false);

    const [availableGames, setAvailableGames] = useState([]);
    const [upcomingGames, setUpcomingGames] = useState([]);

    let scrollLeft = 0;
    let isDown = false;

    useEffect(() => {
        let startX = 0;
        let startY = 0;
        let scrollTop = 0;
        let isDownX = 0;

        setUpcomingGames([]);
        setAvailableGames([]);

        const upcomGames = [];
        const availGames = [];
        games.map(game => {
            if (game.comingSoon || game.gameState === 'unavailable') {
                upcomGames.push(game);
            } else {
                if (game.newGame) {
                    availGames.unshift(game);
                } else {
                    availGames.push(game);
                }
            }
        });
        setUpcomingGames(upcomGames);
        setAvailableGames(availGames);

        getOptions(windowSize, previousWindowSize);

        function setEvents(documentObject) {
            documentObject.addEventListener('mousedown', (e) => {
                isDown = true;
                isDownX = documentObject.scrollLeft;
                startX = e.pageX - documentObject.offsetLeft;
                startY = e.pageY - documentObject.offsetTop;
                scrollLeft = documentObject.scrollLeft;
                scrollTop = documentObject.scrollTop;
            });

            documentObject.addEventListener('mouseup', () => {
                if (!isDown) return;
                isDown = false;
            });

            documentObject.addEventListener('mouseleave', () => {
                if (!isDown) return;
                isDown = false;
            });

            documentObject.addEventListener('touchstart', (e) => {
                isDown = true;
                isDownX = documentObject.scrollLeft;
                scrollLeft = documentObject.scrollLeft;
                scrollTop = documentObject.scrollTop;
            });

            documentObject.addEventListener('touchend', () => {
                if (!isDown) return;
                isDown = false;
            });

            documentObject.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - documentObject.offsetLeft;
                const y = e.pageY - documentObject.offsetTop;
                const walkX = (x - startX) * 1;
                const walkY = (y - startY) * 1;
                documentObject.scrollLeft = scrollLeft - walkX;
                documentObject.scrollTop = scrollTop - walkY;
            });
        }

        const gameslist = document.getElementById('gameslist');
        const gameslistmobile = document.getElementById('gameslistmobile');

        if (gameslist)
            setEvents(gameslist);
        if (gameslistmobile)
            setEvents(gameslistmobile);

    }, [games, windowSize, previousWindowSize, platform]);

    //Get the nr of columns to render per page and the size of each bootstrap col
    function getOptions(windowSize, previousWindowSize) {
        //Only re-render if the window size actually changes or at least one of the window size objects is undefined
        var reRender = false;

        if (
            !windowSize || !windowSize.width
            || !previousWindowSize || !previousWindowSize.width
            || windowSize.width !== previousWindowSize.width
            || platform !== previousPlatform
        ) {
            reRender = true;
        }

        renderGamePages();
    }

    function renderGamePages() {
        const toRenderDesktop = [];
        const toRenderMobile = [];

        const array_move = (arr, old_index, new_index) => {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        };

        setShouldRender(false);

        let newArray = [...availableGames];
        const rbIndex = availableGames.indexOf(availableGames.find(g => g.name === 'runnerbuilder'));
        if (rbIndex >= 0) {
            newArray = array_move(availableGames, rbIndex, 4);
        }
        const garageIndex = availableGames.indexOf(availableGames.find(g => g.name === 'Garage'));
        if (garageIndex >= 0) {
            newArray = array_move(availableGames, garageIndex, 3);
        }
        const bloxIndex = availableGames.indexOf(availableGames.find(g => g.name === 'Blox'));
        if (bloxIndex >= 0) {
            newArray = array_move(availableGames, bloxIndex, 1);
        }

        newArray.map((game) => {
            if (platform) {
                if (Array.isArray(game.platforms)) {
                    const renderGame = game.platforms.find(e => {
                        return e.name === platform;
                    });

                    if (!renderGame) {
                        return;
                    }
                } else {
                    return;
                }
            }

            if (game.platforms.find(p => p.name === 'pc' || p.name === 'telegram')) {
                if (game.platforms.length === 1) {
                    toRenderDesktop.push(
                        renderGameCard(game, toRenderDesktop.length + 1)
                    );

                    if (game.platforms[0].name === 'telegram') {
                        toRenderMobile.push(
                            renderGameCard(game, toRenderMobile.length + 1)
                        );
                    }
                } else {
                    toRenderDesktop.push(
                        renderGameCard(game, toRenderDesktop.length + 1)
                    );
                    toRenderMobile.push(
                        renderGameCard(game, toRenderMobile.length + 1)
                    );
                }
            } else {
                toRenderMobile.push(
                    renderGameCard(game, toRenderMobile.length + 1)
                );
            }
        });

        setToRenderDesktop(
            toRenderDesktop.map((item, index) => {
                return (
                    <div key={index} className="row mx-4 mb-4">
                        {item}
                    </div>
                );
            })
        );

        setToRenderMobile(
            toRenderMobile.map((item, index) => {
                return (
                    <div key={index} className="row mx-4 mb-4">
                        {item}
                    </div>
                );
            })
        );

        setTimeout(() => {
            setShouldRender(true);
        }, 250);
    }

    function renderGameCard(i, index) {
        var label = null;
        var altText = '';
        var customStyle = {
            userSelect: 'none'
        };

        if (i.comingSoon) {
            label = comingSoonBanner;
            altText = 'Game coming soon...';
            customStyle = { maxWidth: '300px' };
        } else {
            if (i.newGame) {
                label = newBanner;
                altText = 'New game';
            } else if (i.gameState === 'unavailable') {
                label = unavailableBanner;
                altText = 'Game is temporarily unavailable';
            } else if (i.gameState === 'alpha') {
                label = alphaBanner;
                altText = 'Game in alpha state';
            } else if (i.gameState === 'beta') {
                label = betaBanner;
                altText = 'Game in beta state';
            } // else if (i.gameState === 'stable') {
            //     if (i.newGame) {
            //         label = newBanner;
            //         altText = 'New game';
            //     }
            // }
        }

        return (
            <div key={i.name} className={`game-card ${i.name === featuredGame ? 'active' : ''}`}>
                <div className='game-number'>
                    {index}
                </div>
                <div className={`position-relative mx-2 my-1 enable-click game-image`}
                    onClick={() => {
                        //console.log(i);
                        if (!isDown) {
                            showDetails(i.name);
                        }
                    }}
                >
                    <div className="tw-image-container">
                        <img
                            alt={i.display_name ? i.display_name : i.name}
                            src={i.imageURL ? i.imageURL : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}`}
                            onError={i => i.target.src = fallBack}
                            className="game-website-tw-image"
                        />

                        {label && (
                            <img
                                src={label}
                                alt={altText}
                                className="game-state-banner"
                                style={customStyle}
                                draggable="false"
                            />
                        )}
                    </div>

                    <div className="games-textblock text-left">
                        <div className="games-text-bg">
                            {i.display_name ? i.display_name : i.name}
                        </div>
                        <div className="games-text-sm">
                            {i.gamedev.display_name ? i.gamedev.display_name : i.gamedev.username}
                            <div>
                                {Array.isArray(i.platforms) && i.platforms.map((e, index) => {
                                    if (e.name === 'ios') {
                                        return (
                                            <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                                <FontAwesomeIcon icon={faApple} />
                                            </div>
                                        );
                                    } else if (e.name === 'android') {
                                        return (
                                            <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                                <FontAwesomeIcon icon={faAndroid} />
                                            </div>
                                        );
                                    } else if (e.name === 'pc') {
                                        return (
                                            <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                                <FontAwesomeIcon icon={faDesktop} />
                                            </div>
                                        );
                                    } else if (e.name === 'telegram') {
                                        return (
                                            <div className='mr-2' style={{ display: 'inline-block' }} key={index}>
                                                <FontAwesomeIcon icon={faTelegramPlane} />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='px-5 pt-5 game-list'>
                <h1>
                    Top Games
                </h1>

                <div className='games-platform'>
                    <div className='g-desktop'>
                        Desktop

                        <div className='games' id='gameslist'>
                            {toRenderDesktop}
                        </div>
                    </div>

                    <div className='g-mobile'>
                        Mobile

                        <div className='games' id='gameslistmobile'>
                            {toRenderMobile}
                        </div>
                    </div>
                </div>
            </div>

            {upcomingGames.length > 0 &&
                <UpcomingGamesList ugames={upcomingGames} showDetails={showDetails}
                    windowSize={windowSize} />
            }
        </>
    );
}

function UpcomingGamesList({ ugames, showDetails, windowSize }) {
    const previousWindowSize = usePrevious(windowSize);
    const [page, setPage] = useState(0);
    const [toRender, setToRender] = useState([]);

    const [shouldRender, setShouldRender] = useState(false);

    const [colNr, setColNr] = useState(8);

    useEffect(() => {
        if (ugames.length > 0) {
            renderGamePages();
        }
    }, [ugames, colNr]);

    useEffect(() => {
        if (windowSize.width < 500) {
            setColNr(3);
        } else if (windowSize.width < 720) {
            setColNr(4);
        } else if (windowSize.width < 990) {
            setColNr(5);
        } else if (windowSize.width < 1040) {
            setColNr(4);
        } else if (windowSize.width < 1150) {
            setColNr(5);
        } else if (windowSize.width < 1490) {
            setColNr(6);
        } else {
            setColNr(8);
        }
    }, [windowSize]);

    function renderGamePages() {
        const maxLines = 1;

        var toRender = [];
        var gameCounter = 0;
        var pageCounter = 1;

        setShouldRender(false);

        ugames.map((game, index) => {
            if (gameCounter < ((colNr > maxLines ? maxLines : colNr) * colNr * pageCounter)) {
                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            } else {
                pageCounter++;

                if (!toRender[pageCounter - 1]) {
                    toRender[pageCounter - 1] = [];
                }

                toRender[pageCounter - 1].push(
                    renderGameCard(game, gameCounter)
                );
            }

            gameCounter++;
        });

        setToRender(
            toRender.map((item, index) => {
                return (
                    <div key={index} className="row mx-0">
                        {item}
                    </div>
                );
            })
        );

        if (page > toRender.length) {
            setPage(0);
        }

        setTimeout(() => {
            setShouldRender(true);
        }, 250);
    }

    function renderGameCard(i, index) {
        const tooltip = (
            <Tooltip id="tooltip">{(i.display_name && i.display_name !== '') ? i.display_name : i.name}</Tooltip>
        );
        return (
            <div className='col' key={'gameCard' + index}>
                <div
                    key={i.name}
                    className={`mx-auto position-relative enable-click`}
                    style={{ width: '70px' }}
                    onClick={() => {
                        showDetails(i.name);
                    }}
                >
                    <OverlayTrigger rootClose placement="bottom" overlay={tooltip}>
                        {({ ref, ...triggerHandler }) => (
                            <div {...triggerHandler} className="mini-tw-image-container">
                                <img
                                    ref={ref}
                                    alt={i.display_name ? i.display_name : i.name}
                                    src={i.imageURL ? i.imageURL : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}`}
                                    onError={i => i.target.src = fallBack}
                                    className="mini-game-website-tw-image"
                                />
                            </div>
                        )}
                    </OverlayTrigger>
                </div>
            </div>
        );
    }

    return (

        <div className='pb-4 px-5 py-4'>
            <div className="row">
                <div className="col-12 col-sm-9 mb-2 main-inner-title">
                    Upcoming Games

                    {ugames.length > 0 && (
                        <span style={{ fontSize: '1.25rem', marginLeft: '3px' }}>
                            ({ugames.length})
                        </span>
                    )}
                </div>
            </div>
            {shouldRender && ugames.length > 0 ? (
                <div className={(windowSize.width < 580) ? '' : 'mx-5'}>
                    <SwipeableViews
                        //enableMouseEvents={true}
                        index={page}
                        onSwitching={pageNr => setPage(pageNr)}
                        slideClassName='d-flex justify-content-center'
                    >
                        {toRender}
                    </SwipeableViews>

                    <Pagination
                        className='text-center mt-4'
                        style={{}} //Empty to override default style
                        dots={toRender.length}
                        index={page}
                        onChangeIndex={pageNr => setPage(pageNr)}
                    />
                </div>
            ) : (
                null
            )}
        </div>
    );
}