import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import * as actions from "../../../actions";
import QUDOServer from "helpers/QUDOServerConnection";

const walletIcon = require("../../../images/definitive/icn-wallet.png");


export function TelegramGameMessageModal({ show, setShow, register, login }) {
    return (
        <Modal
            show={show}
            onHide={() => setShow(null)}
            dialogClassName="meta-box scroll-100"
        >
            <Modal.Body className="">
                <div className="row meta-body">
                    <div className="col-12 telegram-game-modal-text">
                        Welcome to QUDO Games!
                        <br />
                        Congratulations! You received QUDO tokens!
                        <br />
                        <br />
                        To grab your tokens, please
                        <span onClick={register}> register </span>
                        or
                        <span onClick={login}> login </span>
                        to your account!
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function TelegramGameClaimModal({ show, setShow, info, telegramId }) {

    const dispatch = useDispatch();

    const [telegramUser, setTelegramUser] = useState();
    const [rewardLimit, setRewardLimit] = useState(false);
    const [scoreLimit, setScoreLimit] = useState(false);
    const [emptyEnergy, setEmptyEnergy] = useState(false);

    const sameTelegram = (currentTelegramId) => {
        return !telegramId || currentTelegramId === Number(telegramId);
    };

    useEffect(() => {
        if (info.telegramUser) {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const hasUsedEnergy = params.get('energy');
            const game = params.get('gameName');

            if ((hasUsedEnergy && hasUsedEnergy.toLowerCase() === 'true') || hasUsedEnergy === undefined) {
                setEmptyEnergy(false);
                QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/telegramgame/checkrewardlimit`,
                    { telegramID: info.telegramUser.id, gameName: game },
                    { withCredentials: true })
                    .then(response => {
                        setRewardLimit(response.data);
                        if (!response.data) {
                            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/telegramgame/lastscore`,
                                { telegramID: info.telegramUser.id, gameName: game },
                                { withCredentials: true })
                                .then(response => {
                                    setScoreLimit(response.data);
                                });
                        }
                    });
            } else {
                setEmptyEnergy(true);
            }
        }
    }, []);

    useEffect(() => {
        if (telegramUser) {
            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/addtelegram`,
                { telegramUser: telegramUser },
                { withCredentials: true })
                .then(data => {
                    setShow(null);
                })
                .finally(() => {
                    dispatch(actions.fetchUser())
                });
        }
    }, [telegramUser]);

    function TelegramLogin() {
        useEffect(() => {
            // Define the function
            window.onTelegramAuth = (user) => {
                setTelegramUser(user); // {authdate: timestamp, first_name: string, hash: string, id: number, last_name: string, username: string, photo_url: string}
                console.log("Open Telegram bot link: https://t.me/qudocoin")
                //window.open('https://t.me/qudocoin', '_blank')
            };
            //setTimeout(() => {
            const botName = process.env.REACT_APP_TELEGRAM_BOT
            if (global.isDev) console.log("TelegramLogin useEffect on ", botName);
            // Create script element
            const script = document.createElement('script');
            script.src = 'https://telegram.org/js/telegram-widget.js?22';
            script.async = true;
            script.dataset.telegramLogin = botName;
            script.dataset.userpic = 'false';
            script.dataset.size = 'large';
            script.dataset.radius = '5';
            script.dataset.onauth = 'onTelegramAuth(user)';
            script.dataset.requestAccess = 'write';


            document.getElementById('telegram-login').appendChild(script);
            // Append the script to the body
            //document.body.appendChild(script);
            if (global.isDev) console.log(script);

            // Clean up on component unmount
            return () => {
                if (script) {
                    console.log("TelegramLogin useEffect cleanup")
                    document.getElementById('telegram-login').removeChild(script);
                    //document.body.removeChild(script);
                }
            };
            //}, 10);
        }, []);

        return <div id="telegram-login" style={{ margin: "8px auto", width: "fit-content" }}></div>;
    }

    function textToShow() {
        if (emptyEnergy) {
            return 'You just received your activity reward. To receive Highscore rewards you need energy.';
        } else if (rewardLimit) {
            return 'You\'ve reached the highscore reward limit for this game. You can still receive game activity rewards.';
        } else if (scoreLimit) {
            return 'You\'ve reached the score limit for Blox Nano! You received rewards for your game activity and highscore!';
        } else {
            return 'You just received rewards for your game activity and highscores!';
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(null)}
            dialogClassName="meta-box scroll-100"
        >
            <Modal.Body className="">
                <div className="row meta-body">
                    <div className="col-12 login-main-text">
                        {info.telegramUser ?
                            <>
                                {sameTelegram(info.telegramUser.id) ?
                                    <>
                                        {textToShow()}
                                        <br />
                                        <br />
                                        You can check your balance on
                                        <Link
                                            onClick={() => setShow(null)}
                                            to={process.env.REACT_APP_USER_WALLET}
                                            style={{
                                                opacity: 1,
                                                margin: "0.5rem"
                                            }} >
                                            <img
                                                src={walletIcon}
                                                alt="Wallet"
                                                className="icon-32px mr-1"
                                            />
                                            wallet
                                        </Link>
                                        and grab pending tokens!
                                    </>
                                    :
                                    <>
                                        Different Telegram account detected. Login with your
                                        QUDO linked Telegram account to receive rewards!
                                    </>
                                }
                            </>
                            :
                            <>
                                Your account doesn't have a Telegram Account associated.
                                Please link you account using Telegram:

                                <div id="telegram_support_widget">
                                    <TelegramLogin />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}